import React, { useState, FormEvent } from 'react';
import "./App.css";
import Logo from "./assets/logoBlack.svg";
import ScreenImg from './assets/screen.png';
import Background from './assets/background.jpg';
import { useNavigate } from 'react-router-dom';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  reason: string;
}

function DeleteAccount() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    reason: '',
  });

  const [loading, setLoading] = useState(false); // State for loading

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true); // Start loading

    try {
      // const response = await fetch('https://api.skillwheel.co.il/auth/deleteaccount', {
        const response = await fetch('http://localhost:3000/auth/deleteaccount', {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Your request has been submitted.');
      } else {
        alert('There was an issue submitting your request. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting the form. Please try again later.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <>
      <div
        className="App"
        style={{
          display: "flex",
          justifyContent: 'center',
          paddingBlock: 60,
          flexWrap: 'wrap',
          backgroundImage: `url(${Background})`,
          paddingLeft: 120
        }}
      >
        <div
          className="container1"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: 'flex-start',
            maxWidth: '50%'
          }}
        >
          <img src={Logo} onClick={() => { navigate('/') }} alt="logo" width={100} height={40} />

          <h1 style={{ textAlign: "left", fontFamily: "Arimo", fontSize: 50, marginTop: "8%", maxWidth: 570 }}>
            Delete Account Request
          </h1>
          <p style={{ textAlign: "left", fontFamily: "Arimo", fontSize: 18, marginTop: 20, minWidth: 280, maxWidth: 570 }}>
            If you would like to delete your account, please fill out the form below. Once we receive your request, we will review and process it within 7 business days. Deleting your account will permanently remove your data from our systems, and you will no longer have access to any associated services. Please provide the email address associated with your account. We will contact you to confirm your identity and finalize the deletion process.
          </p>

          <form onSubmit={handleSubmit} style={{ marginTop: 40, width: '100%', minWidth: 300, maxWidth: 400 }}>
            <div style={styles.inputGroup}>
              <label htmlFor="firstName" style={styles.label}>First Name:</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                style={styles.input}
              />
            </div>

            <div style={styles.inputGroup}>
              <label htmlFor="lastName" style={styles.label}>Last Name:</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                style={styles.input}
              />
            </div>

            <div style={styles.inputGroup}>
              <label htmlFor="email" style={styles.label}>Email Address:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                style={styles.input}
              />
            </div>

            <div style={styles.inputGroup}>
              <label htmlFor="reason" style={styles.label}>Reason for Deletion (optional):</label>
              <textarea
                id="reason"
                name="reason"
                value={formData.reason}
                onChange={handleChange}
                rows={4}
                style={styles.textarea}
              ></textarea>
            </div>

            <div style={{ textAlign: "left" }}>
              <button type="submit" style={styles.submitButton} disabled={loading}>
                {loading ? 'Processing...' : 'Request Account Deletion'}
              </button>
            </div>
          </form>
        </div>

        <div
          className="imagehand"
          style={{
            backgroundImage: `url(${ScreenImg})`,
            height: '100%',
            width: '50%',
            alignSelf: 'flex-end',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
        </div>
      </div>

      <div style={styles.footer}>
        <p style={styles.footerText}>
          contact us -
        </p>
        <a href="mailto:info@skillwheel.co.il?subject=Website%20support" style={styles.footerLink}>
          info@skillwheel.co.il
        </a>
      </div>
    </>
  );
}

const styles = {
  inputGroup: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column' as const,
  },
  label: {
    fontFamily: "Arimo",
    fontSize: 16,
    textAlign: "left" as const,
    marginBottom: 8,
  },
  input: {
    width: "100%",
    padding: 10,
    fontFamily: "Arimo",
    fontSize: 16,
    borderRadius: 8,
    border: '1px solid #ccc',
  },
  textarea: {
    width: "100%",
    padding: 10,
    fontFamily: "Arimo",
    fontSize: 16,
    borderRadius: 8,
    border: '1px solid #ccc',
  },
  submitButton: {
    backgroundColor: "#FF621A",
    color: "#fff",
    fontFamily: "Arimo",
    fontSize: 16,
    padding: "12px 24px",
    borderRadius: 8,
    border: "none",
    cursor: "pointer",
  },
  footer: {
    height: 60,
    width: '100%',
    position: 'fixed' as const,
    bottom: 0,
    backgroundColor: '#FF621A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerText: {
    fontFamily: "Arimo",
    fontSize: 16,
    fontWeight: 'bold' as const,
    color: "#fff",
    marginRight: 8,
  },
  footerLink: {
    fontFamily: "Arimo",
    fontSize: 16,
    fontWeight: 'bold' as const,
    color: "#fff",
    textDecoration: 'none' as const,
  },
};

export default DeleteAccount;
