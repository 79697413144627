/* eslint-disable jsx-a11y/alt-text */
import "./App.css";
import Mock1 from "./assets/mock1.png";
import Gplay from './assets/googlePlay.svg'
import Stars from './assets/Stars.svg'
import Astore from './assets/appstore.svg'
import Logo from './assets/BlackLogo.svg'
import Grid from './assets/grid.png'
import Flags from './assets/flags.svg'
import Screen1 from './assets/screen1.png'
import Screen2 from './assets/screen2.png'
import Screen3 from './assets/screen3.png'
import FooterImg from './assets/footerImg.jpg'
import { useNavigate } from 'react-router-dom';


const AppScreenBlock = ({img, title, description}) => {

  return (
    <div className="AppScreenBlockContainer">
      <img className="AppScreenBlockImg" src={img}/>
      <h3 className="AppScreenBlockTitle">{title}</h3>
      <p className="AppScreenBlockText">{description}</p>
    </div>
  )
}

const Buttons = () => {
  return (
    <>
            <a href="https://play.google.com/store/apps/details?id=com.skillwheel.test" target='_blank' rel="noreferrer">
            <button 
              className="storeButton"
              style={{ 
                  backgroundImage: `url(${Gplay})`,
                  marginBottom:10
            }}/>
          </a>

          <a href="https://apps.apple.com/us/app/driving-theory-test-israel-25/id6504748840" target='_blank' rel="noreferrer">
            <button 
            className="storeButton"
            style={{backgroundImage: `url(${Astore})`}}/>
            </a>
    </>
  )
}


function App() {

  const navigate = useNavigate()

  return (
    <>

    <div className="mobileContainer">

        <div className="yearLogoContainer yearLogoContainer-mobile">
          <div className="yearContainer">
            <p className="currentYear, currentYear-mobile">{new Date().getFullYear()}</p>
          </div>
          <img height={30} style={{}} alt="Logo" src={Logo}/>
        </div>

        <div className="leftContainer leftContainer-mobile">
          <h1 className="header">עבור את המבחן מהפעם הראשונה!</h1>
          <h2 className="header2">אפליקציה למבחן תאוריה: לימוד תאוריה לנהיגה לרכב ואופנוע</h2>
          <img src={Mock1} className="mock1"/>
        </div>

        <div className="buttonsContainer">
          <div className="ratingContainer">
            <p className="rating">5.0</p>
            <img src={Stars}/>
          </div>
          <p className="downloadText">התחילו ללמוד בחינם עכשיו! גישה מיידית לכל המבחנים והחומר</p>
          <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
            <Buttons/>
          </div>
        </div>

        <div className="centralContainer" style={{backgroundImage: `url(${Grid})`}}>
          <div className="benefitContainer">
            <p className="langs">6 שפות</p>
            <img src={Flags}/>
          </div>

          <div className="benefitContainer benefitContainer2">
            <p className="categoriesHeader">6 קטגוריות</p>
            <p className="categories">A B C C1 D 1</p>
          </div>
        </div>

        <section className="screensSection">
          <AppScreenBlock img={Screen1} title='תוכנית לימודים והתקדמות בכל הנושאים!' description='חזרו עד שתעברו את הבחינה בהצטיינות!'/>
          <AppScreenBlock img={Screen2} title='אימונים גמישים וסימולטור בחינה!' description='בחרו נושאים ומספר שאלות. + תרגול רק על טעויות מהלימודים'/>
          <AppScreenBlock img={Screen3} title='קטלוג מלא של שאלות עם חיפוש, סינונים ומועדפים!' description='אתגר מקוון מיוחד +'/>
          <div className="backLine"/>
          {/* <img className="scrensGridImg" src={Grid}/> */}
        </section>

        <section className="footer2">
          <img className="FooterImg" src={FooterImg}/>
          <div className="footerBlock2">
            <h3 className="footerTitle">הורד עכשיו וצלח את המבחן בפעם הראשונה</h3>
            <div className="footerButtons">
              <Buttons/>
            </div>
            <img className="footerGrid" src={Grid}/>
          </div>
        </section>

        <div onClick={()=>{navigate('/support')}}>
      <p className="supportLink"> Support </p>
    </div>
    </div>



    <div className="desctopContainer">
    <section className="mainContainer">
      <div className="leftContainer">
          <img src={Mock1} className="mock1"/>
      </div>

      <div className="centralContainer" style={{backgroundImage: `url(${Grid})`}}>
        <div className="benefitContainer">
          <p className="langs">6 שפות</p>
          <img src={Flags}/>
        </div>

        <div className="benefitContainer benefitContainer2">
          <p className="categoriesHeader">6 קטגוריות</p>
          <p className="categories">A B C C1 D 1</p>
        </div>
      </div>

      <div className="rightContainer">
        <div className="yearLogoContainer">
          <div className="yearContainer">
            <p className="currentYear">{new Date().getFullYear()}</p>
          </div>
          <img height={30} style={{}} alt="Logo" src={Logo}/>
        </div>

        <h1 className="header">עבור את המבחן מהפעם הראשונה!</h1>
        <h2 className="header2">אפליקציה למבחן תאוריה: לימוד תאוריה לנהיגה לרכב ואופנוע</h2>
        <h3 className="header3">התחילו ללמוד תאוריה לנהיגה לרכב ואופנוע עם שאלות מבחן</h3>

        <div className="buttonsContainer">
          <div className="ratingContainer">
            <p className="rating">5.0</p>
            <img src={Stars}/>
          </div>
          <p className="downloadText">התחילו ללמוד בחינם עכשיו! גישה מיידית לכל המבחנים והחומר</p>
          <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
            <Buttons/>
          </div>
        </div>
      </div>
    </section>



    <section className="screensSection">
      <AppScreenBlock img={Screen1} title='תוכנית לימודים והתקדמות בכל הנושאים!' description='חזרו עד שתעברו את הבחינה בהצטיינות!'/>
      <AppScreenBlock img={Screen2} title='אימונים גמישים וסימולטור בחינה!' description='בחרו נושאים ומספר שאלות. + תרגול רק על טעויות מהלימודים'/>
      <AppScreenBlock img={Screen3} title='קטלוג מלא של שאלות עם חיפוש, סינונים ומועדפים!' description='אתגר מקוון מיוחד +'/>
      <div className="backLine"/>
      <img className="scrensGridImg" src={Grid}/>
    </section>

    
    <section className="footer2">
      <img className="FooterImg" src={FooterImg}/>
      <div className="footerBlock2">
        <h3 className="footerTitle">הורד עכשיו וצלח את המבחן בפעם הראשונה</h3>
        <div>
          <Buttons/>
        </div>
        <img className="footerGrid" src={Grid}/>
      </div>
    </section>


    <div onClick={()=>{navigate('/support')}}>
      <p className="supportLink"> Support </p>
    </div>
    </div>
    </>
  );
}

export default App;
